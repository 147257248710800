import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import '../../src/App.css';
import racebaselogo from '../racebaselogo_symbol.png';
import racebaseText from '../racebaselogo_text.png';
import * as Realm from "realm-web";
import axios from 'axios';
import { PropagateLoader } from 'react-spinners';

const appId = process.env.REACT_APP_PROJECT_ID;
const app = new Realm.App({ id: appId });

const params = new URLSearchParams(window.location.search);
const token = params.get('token');
const tokenId = params.get('tokenId');
const email = params.get('email');

const EmailConfirmation = () => {
  const [loading, setLoading] = useState(true);
  const [confirmationState, setConfirmationState] = useState({status: null, message: null});
  const logoRef = useRef(null)
  const hasLoadedBefore = useRef(false)

  function displayResult(result, error) {
    if (result === "success") {
      setConfirmationState({status: 'success', message: 'Your email has been verified. You can close this page and connect to the app. Thank you.'})
    } else if (result === "error") {
      setConfirmationState({status: 'fail', message: error})
    }
    setTimeout(() => { setLoading(false) }, 2000);
  }

  useLayoutEffect(() => {
    const confirmUserWithEmail = async () => {
      console.log('Confirm Users Email')
      if (token && tokenId) {
        try {
          await app.emailPasswordAuth.confirmUser({ token, tokenId });

          await callRealmFunction();
          displayResult('success');
        } catch (error) {
          displayResult('error', 'An error occurred while confirming user.');
        }
      }
    };

    if(!hasLoadedBefore.current){     //Component loads twice - intentional measure by the React team to ensure your components are pure functions. (this makes sure the useEffect runs only once)
      hasLoadedBefore.current = true;
      confirmUserWithEmail();
    }
  }, []);

  useEffect(()=> { console.log('confirmationState', confirmationState) }, [confirmationState])

  const callRealmFunction = async () => {
    const endpointUrl = `https://europe-west1.gcp.data.mongodb-api.com/app/${appId}/endpoint/confirmUsersEmail`;
    
    const requestData = {
      token: token,
      tokenId: tokenId,
      email: email
    };

    try {
      const response = await axios.post(endpointUrl, requestData, {
        headers: {
          'Content-Type': 'application/json',
          //Authorization: `Bearer ${tokenId}`,
        }
      });

      //console.log('Response status:', response.status, response.data);

    } catch (error) {
      throw new Error(`${error.message}`)
    }
  };
  
  return (
    <div className="App">
      <header className="App-header">
        <img ref={logoRef} src={racebaselogo} className="App-logo" alt="RACEBASE_LOGO" />


        {!loading ? (
          <div className={confirmationState.status === 'success' ? 'success-message' : 'error-message'}>
            <p>{confirmationState.message}</p>
          </div>
        ) : (
          <div className="spinner">
            <PropagateLoader 
              color={'white'} 
              loading={loading} 
              size={10} 
            />
          </div>
        )}
      
      </header>

      <div className="bottom-corner">
        <img src={racebaseText} alt="RACEBASE" className="bottom-corner-img" />
        <p className="bottom-corner-text">2024</p>
      </div>
    </div>
  );
}

export default EmailConfirmation;