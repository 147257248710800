import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as Realm from "realm-web";
import '../../src/App.css';
import racebaselogo from '../racebaselogo_symbol.png';
import racebaseText from '../racebaselogo_text.png';

import { PropagateLoader } from 'react-spinners';

const appId = process.env.REACT_APP_PROJECT_ID;
const app = new Realm.App({ id: appId });

// Debounce function
const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            func.apply(null, args);
        }, delay);
    };
};

const ResetPassword = () => {
    const [loading, setLoading] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [response, setResponse] = useState({ status: false, message: '' });
    const [fadeClass, setFadeClass] = useState('fade-in');
    const location = useLocation();
    const logoRef = useRef(null)

    // Parse the token from the URL
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const tokenId = query.get('tokenId');
    const email = query.get('email');

    const handleResetPassword = async () => {
        if (newPassword !== confirmPassword) {
            setResponse({status: false, message: 'Passwords do not match.'});
            return;
        }

        if (newPassword.length <= 5) {
            setResponse({ status: false, message: 'Password must be longer than 5 letters.' });
            return; 
        }

        try {
            setLoading(true)
            const response = await callRealmFunction()
            setResponse({status: response.status, message: response.message});
        } catch (error) {
            console.log('Error', error.message)
            setResponse({status: false, message: 'Error occurred: ' + error.message});
        } finally {
            setLoading(false)
        }
    };

    const callRealmFunction = async() => {
        try {
            await app.emailPasswordAuth.resetPassword({
                password: newPassword,
                token,
                tokenId,
            });
            return {status: 'success', message: 'Password Reset Successfully! Please close this page.'}
        } catch (error) {
            console.log('Error in callRealmFunction', error.message)
            return {status: 'fail', message: error.message}
        }
    };
    

    // Debounced function to reset response state
    const resetResponseDebounced = debounce((newMessage) => {
        setFadeClass('fade-in')
        setResponse({ status: false, message: newMessage });
    }, 300); // 300 milliseconds delay

    useEffect(() => {
        if (response.message !== "") {
            setFadeClass('fade-in-active'); // Trigger fade-in effect
        }
    }, [response.message]);

    return (
        <div className="App">
            <header className="App-header">
                <img ref={logoRef} src={racebaselogo} className="App-logo" alt="RACEBASE_LOGO" />

                {!loading ? (
                    <div className={`${fadeClass} ${response.status == 'success' ? 'success-message' : 'error-message'}`}>
                        <p>{response.message}</p>
                    </div>
                ) : (
                    <div className="spinner">
                        <PropagateLoader 
                            color={'white'} 
                            loading={loading} 
                            size={10} 
                        />
                    </div>
                )}
    
                <div className="reset-password">
                    <h1>Reset Password</h1>
                    <div className="form-group">
                        <input
                            type="password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => {
                                setNewPassword(e.target.value)
                                resetResponseDebounced('')
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => {
                                setConfirmPassword(e.target.value)
                                resetResponseDebounced('')
                            }}
                        />
                    </div>
                    <button onClick={handleResetPassword}>Submit</button>
                </div>
            </header>
  
        <div className="bottom-corner">
          <img src={racebaseText} alt="RACEBASE" className="bottom-corner-img" />
          <p className="bottom-corner-text">2024</p>
        </div>
      </div>
    );
};

export default ResetPassword;
